import React from "react";

const PasswordResetPage = () => {
  return (
    <div style={{ minHeight: "100vh" }}>
      <h1>Reset Password Page</h1>
    </div>
  );
};

export default PasswordResetPage;
